import gql from 'graphql-tag';
import { adminInputFieldFragment } from 'graphql/fragments';

const adminInputFieldsQuery = gql`
  query adminInputFieldsQuery($categoryId: ID!, $isLibrary: Boolean) {
    inputs(categoryId: $categoryId, isLibrary: $isLibrary) {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;

export default adminInputFieldsQuery;
